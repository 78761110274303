import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"

// components
import Layout from "../components/layout"
import Eligibility from "../components/TaxFreeShopping/taxFreeComponents/Eligibility"
import HeroTaxFree from "../components/TaxFreeShopping/taxFreeComponents/HeroTaxFree"
import TaxFreecards from "../components/TaxFreeShopping/taxFreeComponents/TaxFreecards"

// helpers
import { pageDirection } from "../helpers/utils"
import { useTranslation } from "gatsby-plugin-react-i18next"

const TaxFreeShopping = () => {
  const dir = pageDirection()
  const { t } = useTranslation()
  return (
    <Layout page="vat" footerBgVariant="light" currentpage={t("localVatRules")}>
      <Seo title="Learn more about local tax free shopping rules" />
      <HeroTaxFree dir={dir}></HeroTaxFree>
      <TaxFreecards dir={dir}></TaxFreecards>
      <Eligibility></Eligibility>
    </Layout>
  )
}

export default TaxFreeShopping

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
