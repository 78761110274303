import React from "react"
import "./HeroTaxFree.css"
import Dinar from "../../../assets/images/dinar.png"
import Dinarmobile from "../../../assets/images/dinarmobile.svg"
import calender from "../../../assets/icons/calender.svg"
import Vat from "../../../assets/icons/vat.svg"
const TaxFreecards = ({ dir }) => {
  return (
    <section dir={dir} className="tax-free-card-bg">
      <div className="container-lg">
        <div className="flex-container-for-tax-card">
          <article className="flex-item-tax-free-one">
            <span className="inner-text-of-card-one">Currency</span>
            <h4 className="inner-text-of-card-two">Dirham (AED)</h4>
            <img
              src={Dinar}
              alt="united arab emirate coin"
              className="card-free-tax-icon"
            />
            <img
              src={Dinarmobile}
              id="dinar-desktop"
              alt="united arab emirate coin"
            />
            <span className="inner-text-of-card-one  inner-text-of-card">
              Minimum Spend
            </span>
            <h4 className="inner-text-of-card-four">250AED</h4>
          </article>
          <div className="flex-container-inner ">
            <article className="flex-item-tax-free-two">
              <img src={Vat} alt="vat icon" />
              <div className="vat-text-positioning">
                <h4 className="vat-percentage d-flex">5%</h4>
                <h3 className="standard-rate d-flex">
                  Standard <br className="brake"></br>rate
                </h3>
              </div>
            </article>
            <article className="flex-item-tax-free-three">
              <p className="inner-text-of-card-three">
                Time limit to get export validation
              </p>
              <h3 className="d-flex">90 days from the date of export</h3>
            </article>
          </div>
          <article className="flex-item-tax-free-four">
            <span className="inner-text-of-card-three">
              Time limit to claim refund
            </span>
            <div className="flex-month-calendar">
              <h3 className="d-flex mx-1">12months from the date of export validation</h3>
              <img src={calender} alt="calendar icon" id="calendar" />
            </div>
          </article>
        </div>
      </div>
    </section>
  )
}

export default TaxFreecards
