import React from "react"
import { Trans } from "react-i18next";

// styles
import "./HeroTaxFree.css";

//images
import Uaetower from "../../../assets/images/uaetower.svg";

const HeroTaxFree = ({ dir }) => {
  return (
    <div dir={dir} className="container-lg hero-tax-section-height">
      <h1 className="d-flex tax-free-shopping-text">
        <Trans>localVatRules</Trans>
      </h1>
      <div className="hero-icon-container">
        <img src={Uaetower} alt="tax free shopping icon"></img>
      </div>
    </div>
  )
}

export default HeroTaxFree
