import React, { useState, useEffect } from "react"
import Alert from "../../../assets/icons/alert.svg"
import { useSwipeable } from "react-swipeable"

// data
import data from "../../../assets/data.json";

// helpers
import { pageDirection } from "../../../helpers/utils";

const Eligibility = () => {
  const [active, setActive] = useState(0);
  const dir = pageDirection();
  // eslint-disable-next-line
  const listenerCircle = [
    <div></div>,
    <div></div>,
    <div></div>,
    <div></div>,
    <div></div>,
    <div></div>,
  ]
  const handleIndexClick = event => {
    let num = { active: +event.target.dataset.index }
    setActive(num.active)
  }
  const handleNext = () => {
    active < data.additionalinfo.length - 1 && setActive(active + 1)
  }
  const handleBack = () => {
    active > 0 && setActive(active - 1)
  }

  useEffect(() => {
    const handleSlide = () => {
      if (active < data.additionalinfo.length - 1) {
        let timeOutId = setTimeout(() => {
          setActive(active + 1)
          clearTimeout(timeOutId)
        }, 15000)
      } else if (active >= data.additionalinfo.length - 1) {
        let timeOutIdTwo = setTimeout(() => {
          setActive(0)
          clearTimeout(timeOutIdTwo)
        }, 15000)
      }
    }
    handleSlide()
  }, [active])
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handleBack(),
  })

  return (
    <div dir={dir} className="eligibitity-background">
      <div className="container-lg">
        <h3 className="d-flex eligibility-text-intro">Eligibility for VAT refunds</h3>
        <hr></hr>
        <p className="d-flex eligibility-text">
          Maecenas lacinia lacus in viverra gravida. Vestibulum ut mi in nisi
          laoreet viverra eu ultricies ante. Cras mollis ex neque, non pharetra
          ex ultricies auctor. Integer id dapibus orci, id volutpat diam. Sed ac
          dolor sed nibh pellentesque malesuada nec et ligula. Sed in varius
          mauris, tincidunt auctor felis. Nunc euismod vel augue at accumsan.
          Nullam condimentum elit nisi, venenatis hendrerit nisi lobortis eget.
          In cursus pulvinar eleifend. Nulla tincidunt, dolor id facilisis
          porta, turpis dolor aliquet neque, in malesuada felis sem non dolor.
          Ut semper, purus nec mattis sodales, nisl nulla posuere eros, at
          dignissim nisl enim quis arcu. Nunc suscipit, leo a vehicula faucibus,
          ex diam porta arcu, ut euismod risus ante id arcu.<br></br>
          <br></br> Vestibulum fermentum venenatis gravida. Etiam nibh eros,
          pretium sit amet diam ut, tempus congue dolor. Donec laoreet interdum
          dui, nec suscipit ipsum pulvinar nec. In id risus in justo dictum
          condimentum. Sed et ullamcorper nisi. Mauris ultricies sapien id elit
          luctus bibendum. Nam pharetra, dolor et volutpat congue, sapien metus
          blandit nulla, ut tincidunt est risus sed enim. In pharetra justo
          eros, et ultrices leo suscipit porta. Phasellus sed nisi at erat
          aliquet sagittis. Vestibulum aliquam massa a eros pulvinar, quis
          varius orci elementum. Praesent tincidunt dolor mi, et faucibus sem
          fringilla at. Nulla facilisi. Curabitur accumsan iaculis odio, ut
          molestie eros lobortis vitae.
        </p>
        <hr></hr>
        <section className="additional-flex">
          <span>
            <img src={Alert} alt="alert icon" />
          </span>
          <h4>Additional Information</h4>
        </section>
        <section className="eligibility-flex-container container-space">
          <article className="eligibility-item-one">
            <h3 className="d-flex">Location of the validation points</h3>
            <p>
              At all the Civil Airports, Seaports and Land Borders in the UAE.
              Just follow the signs for Tax Free refunds before approaching the
              immigration counters, and our staff will assist you.
            </p>
          </article>
          <article className="eligibility-item-two">
            <h3>What goods are eligible for tax refund</h3>
            <p>
              All goods that have vat are eligible for tax refunds except:{" "}
              <br></br>1. Goods that have been consumed, fully or partly, in the
              UAE or any; <br></br>2. Motor vehicles, boats and aircrafts;
              <br></br> 3. Goods that are overseas tourist at the time of
              leaving the UAE
            </p>
            <p></p>
          </article>
          <article className="eligibility-item-three">
            {" "}
            <h3>What’s required at the time of export validation</h3>
            <p>
              At all the Civil Airports, Seaports and Land Borders in the UAE.
              Just follow the signs for Tax Free refunds before approaching the
              immigration counters, and our staff will assist you.
            </p>
          </article>
        </section>
        <section className="eligibility-flex-container space-bottom">
          <article className="eligibility-item-four">
            <h3>Refund options</h3>
            <p>
              1. Cash (there is a limit of 7,000AED for cash refunds)<br></br>{" "}
              2. Credut Card (Visa, Mastercard, Amex, UnionPay)<br></br> 3.
              Digital Wallet (WeChat Pay)
            </p>
          </article>
          <article className="eligibility-item-five">
            <h3>Refund Amount and Fees</h3>
            <p>
              You will receive 85% of the tax paid, minus a feed of 4.80AED per
              Tax Free validated.
            </p>
          </article>
          <article className="eligibility-item-six">
            <h3>Advice</h3>
            <p>
              Please leave enough time for the export validation processes
              before your flight departs.
            </p>
          </article>
        </section>
      </div>
      <section className="background-img-carousel">
        <div className="container-lg">
          <div>
            <h3 className="carousel-text">
              {data.additionalinfo[active].title}
            </h3>
            <p>{data.additionalinfo[active].content}</p>
          </div>

          <div className="carousel-listener-flex " {...handlers}>
            {" "}
            {listenerCircle.map((cirlce, index) => (
              <div
                id="circle-event-space"
                key={index}
                data-index={index}
                onClick={handleIndexClick}
                {...handlers}
                className={
                  index === active ? "active-story" : "non-active-story"
                }
              >
                {cirlce}
              </div>
            ))}{" "}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Eligibility
